import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";
import { computePosition, offset, autoUpdate, autoPlacement } from "@floating-ui/dom";
export default class extends Controller {
    static values = {
        open: Boolean
    }; // Defines a values, eg; like on called "open" that is a boolean using data-dropdown-open-value="true" or "false" for state on an element
    static targets = ["trigger", "card"]; // Gets the elements and their values with the data-dropdown-target="card" attribute, renders to this.cardTarget in this case

    initialize() {
        this.trigger = this.triggerTarget;
        this.card = this.cardTarget;
        this.isOpen = this.openValue;
        // Use the click outside hook to close the dropdown when clicking outside
        useClickOutside(this, { element: this.card })
    }

    connect() {
        // Update the position of the card on connect
        this.updatePosition(); 
        this.cleanup = autoUpdate(this.trigger, this.card, this.updatePosition.bind(this));
    }

    disconnect() {
        if (this.cleanup) {
            this.cleanup();
        }
    }

    toggle() {
        this.openValue = !this.openValue;
        this.updateVisibility();
    }

    open() {
        this.openValue = true;
        this.updateVisibility();
    }

    close() {
        this.openValue = false;
        this.updateVisibility();
    }

    clickOutside(e) {
        // Check if the click event originated from the close button
        if (e.target.closest('[data-action="click->dropdown#toggle"]')) {
            return; // If so, return early, because the button will handle the event
        }
        e.stopImmediatePropagation();
        this.close();
    }

    updateVisibility() {
        if (this.openValue) {
            this.card.classList.remove("tw-hidden");
            this.updatePosition();
        } else {
            this.card.classList.add("tw-hidden");
        }
    }

    updatePosition() {
        // Compute the position of the card w/ floating-ui. 
        computePosition(this.trigger, this.card, {
            middleware: [
                offset({ mainAxis: 10 }),  // offset the card by 10px
                // use autoPlacement middleware to ensure the card is placed within the viewport
                // whether on the left or right side of the page
                autoPlacement({
                    allowedPlacements: ['bottom-start', 'bottom-end'],
                }),
            ],
        }).then(({ x, y }) => {
            Object.assign(this.card.style, {
                left: `${x}px`,
                top: `${y}px`,
            });
        });
    }
}