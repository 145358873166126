import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";
export default class extends Controller {
    static values = { open: Boolean }; // Defines a value called "open" that is a boolean using data-modal-open-value="true" or "false" for state on an element
    static targets = ["container", "card"]; // Get the element with the data-modal-target="container" attribute
    

    connect() {
        // modals should be hidden by default, so we have no state to update on init
        
        // add event listeners here
        useClickOutside(this, { element: this.cardTarget });
    }

    toggle() {
        this.openValue = !this.openValue;
        this.updateVisibility();
    }

    // clickOutside(event) closes the modal when clicking outside of it (using the stimulus-use plugin)
    clickOutside(event) {
        event.preventDefault();
        this.close();
    }

    close() {
        this.openValue = false;
        this.updateVisibility();
    }

    updateVisibility() {
        if (this.openValue) {
            this.containerTarget.classList.remove('tw-hidden'); // remove the hidden class
        } else {
            this.containerTarget.classList.add('tw-hidden'); // add it back
        }
    }
}