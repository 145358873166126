import { Application } from "@hotwired/stimulus";
import { registerControllers } from 'stimulus-vite-helpers'

const application = Application.start();
// application.debug = true; // enable debugging if needed

window.Stimulus = application;
/* 
    Find all the Stimulus controllers in the project 
*/

// Generic controllers
const genericControllers = import.meta.glob("../../frontend/**/*_controller.{js,ts}", { eager: true })  // any file matching the pattern anywhere in the app/frontend folder (*_controller.js or *_controller.ts)
registerControllers(application, genericControllers)

// Controllers from components
const componentControllers = import.meta.glob("../components/**/controller.{js,ts}", { eager: true }); // any file named controller.js in the components folder
for (const path in componentControllers) {
    // extract the name of the component from the path (should be between ../components /controller.js & like -> ../components/modal/controller.js
    // Convert path into a controller identifier: ../components/modal/controller.js -> modal (so we can use <%= component_name %> in the template)
    let module = componentControllers[path];
    const componentModule = path.match(/components\/(.*)\/controller.js/)
    const componentName = componentModule ? componentModule[1] : null;
    if (componentName) {
        application.register(componentName, module.default);
    }
}

export default application;